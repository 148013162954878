/**
 * Single blog post
 */

// Import statements
import React from "react"
import {graphql, Link} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"
import parse from "html-react-parser"
import "../styles/files/single-post.scss"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
//import "../styles/@wordpress/block-library/build-style/style.styles"
//import "../styles/@wordpress/block-library/build-style/theme.styles"
import Layout from "../components/layout"
import Body from "../components/body"
import Seo from "../components/common/seo"
import AdFooter from "../components/ad/ad-footer";
import Author from "../components/common/author";
import AdSkyscraper from "../components/ad/ad-skyscraper";
import InstagramFeed from "../components/common/instagram-feed";
import SocialMedia from "../components/common/socialmedia";
import BlogComments from "../components/comment/comments";
import {formatDate} from "../components/common/format-date"

/**
 * Define the BlogPostTemplate constant
 * @param previous
 * @param next
 * @param post
 * @param comments
 * @returns {JSX.Element}
 * @constructor
 */
const BlogPostTemplate = ({data: {previous, next, post, comments}}) => {

    // define the featuredImage of this post
    const featuredImage = {
        data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
        alt: post.featuredImage?.node?.alt || ``,
    }

    // get the publication date
    const publication_date = new Date(post.date);

    // return the information
    return (
        <Layout>
            <Body bodyClass="single single-post" />
            <Seo title={post.title} description={post.excerpt} />

            <article className="post">

                <header className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1">
                        <h1 className="entry-title" itemProp="name">{parse(post.title)}</h1>
                        <ul className="list-inline uppercase">
                            <li key="publication_date">{formatDate(publication_date)}</li>
                            <li key="author">Door {post.author.node.uri && (
                                <Link to={post.author.node.uri}>{post.author.node.name}</Link>
                            )}</li>
                        </ul>
                    </div>
                </header>

                <div className="row" id="header-single-image">
                    <nav className="d-flex justify-content-between">
                        <ul className="pager">
                            {previous && (
                                <li className="pager-button btn btn-grey previous">
                                    <Link to={previous.uri} rel="prev" className="tip tip-left"
                                          data-title={parse(previous.title)}>
                                        ← vorige
                                    </Link>
                                </li>
                            )}
                            {next && (
                                <li className="pager-button btn btn-grey next">
                                    <Link to={next.uri} rel="next" className="tip tip-right"
                                          data-title={parse(next.title)}>
                                        volgende →
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </nav>
                    <div className="col-md-10 offset-md-1">
                        {/* if we have a featured image for this post let's display it */}
                        {featuredImage?.data && (
                            <GatsbyImage
                                image={featuredImage.data}
                                alt={featuredImage.alt}
                            />
                        )}
                    </div>
                </div>

                <div className="row single-content" id="post-content">
                    <div className="col-md-8 col-lg-7 offset-lg-1 order-1 col-no-padding" id="post-content-body">

                        <AdFooter/>

                        <div id="single-blog-text">
                            <div id="result"></div>
                            {!!post.content && parse(post.content)}
                        </div>
                        <div id="recipe-footer">
                            <div id="author-single-meta">
                                <Author author={post.author.node}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 order-2" id="sidebar-right">
                        <AdSkyscraper/>
                        <InstagramFeed/>
                        <SocialMedia title={post.title} featuredImage={featuredImage}/>
                    </div>
                </div>

                <BlogComments post={post} comments={comments}/>

            </article>

        </Layout>
    )
}

/**
 * Export the BlogPostTemplate variable
 */
export default BlogPostTemplate

/**
 * Get the single post
 * @type {StaticQueryDocument}
 */
export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
        id
        excerpt
        content
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 50
                  placeholder: DOMINANT_COLOR
                  layout: FULL_WIDTH
                  breakpoints: [546,605,805,955,1105]
                )
              }
            }
          }
        }
        author {
          node {
            databaseId
            name
            uri
            description
            nicename
            email
            slug
            avatar {
              height
              size
              url
              width
            }
          }
        }
      }
      comments: allWpComment(filter: {commentedOn: {node: {id: { eq: $id } } } } ) {
        edges {
          node {
            id
            databaseId
            author {
              node {
                id
                name
                email
                url
                databaseId
              }
            }
            date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
            content
            parentDatabaseId
          }
        }
      }
      previous: wpPost(id: { eq: $previousPostId }) {
        uri
        title
      }
      next: wpPost(id: { eq: $nextPostId }) {
        uri
        title
      }
  }
`
